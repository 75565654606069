import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import viewReducer from './slices/viewSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        view: viewReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
