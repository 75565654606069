import { CPagination, CPaginationItem } from '@coreui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '../components/listItem';
import SearchBar from '../components/searchBar';
import { getUsers, setCurrentPage } from '../slices/viewSlice';
import { AppDispatch, RootState } from '../store';
import { Constants } from '../utils/constants';
import './search.css';

const Search = () => {
    const dispatch = useDispatch<AppDispatch>();
    const totalPageCount = useSelector((state: RootState) => state.view.totalPageCount);
    const items = useSelector((state: RootState) => state.view.rows);
    const currentPageSelected = useSelector((state: RootState) => state.view.currentPageSelected);

    useEffect(() => {
        (async () => {
            try {
                const offset = totalPageCount
                    ? (currentPageSelected - 1) * Constants.PAGE_ITEMS_PER_PAGE
                    : 0;
                await dispatch(
                    getUsers({
                        offset: offset,
                        limit: Constants.PAGE_ITEMS_PER_PAGE,
                        totalResults: true,
                    })
                ).unwrap();
            } catch (error: unknown) {
                console.log(error);
            }
        })();

        return () => {};
    }, [currentPageSelected]);

    const handlePageClick = (page: string | number) => {
        switch (page) {
            case 'next':
                if (currentPageSelected < totalPageCount) {
                    dispatch(setCurrentPage(currentPageSelected + 1));
                }
                break;
            case 'previous':
                if (currentPageSelected > 1) {
                    dispatch(setCurrentPage(currentPageSelected - 1));
                }
                break;
            default:
                dispatch(setCurrentPage(page));
                break;
        }
    };

    return (
        <>
            <div className="search-container">
                <SearchBar></SearchBar>
                <div className="list-container">
                    {(items || []).map((testitem, index) => (
                        <ListItem key={index} item={testitem}></ListItem>
                    ))}
                </div>
                <CPagination aria-label="Page navigation example">
                    <CPaginationItem
                        key={'previous'}
                        onClick={() => handlePageClick('previous')}
                        aria-label="Previous"
                    >
                        <span aria-hidden="true">&laquo;</span>
                    </CPaginationItem>

                    {(() => {
                        const content = [];
                        for (let i = 1; i <= totalPageCount; i++) {
                            content.push(
                                <CPaginationItem
                                    active={currentPageSelected === i}
                                    key={i}
                                    onClick={() => handlePageClick(i)}
                                >
                                    {i}
                                </CPaginationItem>
                            );
                        }
                        return content;
                    })()}

                    <CPaginationItem
                        key={'next'}
                        onClick={() => handlePageClick('next')}
                        aria-label="Next"
                    >
                        <span aria-hidden="true">&raquo;</span>
                    </CPaginationItem>
                </CPagination>
            </div>
        </>
    );
};

export default Search;
