import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';

export enum Tab {
    Home = 'home',
    Search = 'search',
    Register = 'register',
    Login = 'login',
    Profile = 'profile',
}

const initialState: {
    isLoading: boolean;
    isAuthenticated: boolean;
    row: {
        name?: string;
        email?: string;
    };
    tab: Tab;
} = {
    isAuthenticated: false,
    isLoading: false,
    row: {},
    tab: Tab.Search,
};

export const postUser = createAsyncThunk(
    'users/postUser',
    async (userFormData: unknown, thunkAPI) => {
        try {
            return (await axios.post(`/api/v1/users`, userFormData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })) as AxiosResponse;
        } catch (error: any) {
            const message = error?.response?.data?.message || error?.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loginUser = createAsyncThunk(
    'users/loginUser',
    async (userFormData: any, thunkAPI) => {
        try {
            return (await axios.post(`/api/v1/login`, userFormData, {
                headers: { 'Content-Type': 'application/json' },
            })) as AxiosResponse;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data?.message);
        }
    }
);

export const logoutUser = createAsyncThunk('users/logoutUser', async (payload: null, thunkAPI) => {
    try {
        return await axios.get(`/api/v1/logout`);
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        showLoading: (state) => {
            return { ...state, isLoading: true };
        },
        hideLoading: (state) => {
            return { ...state, isLoading: false };
        },
        setAuthenticated: (state) => {
            return { ...state, isAuthenticated: true };
        },
        setCurrentTab: (state, { payload }) => {
            return { ...state, tab: payload as Tab };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(postUser.fulfilled, (state, action) => {
            const response = action.payload?.data?.item;
            state.isLoading = false;
            state.row = response;
            state.isAuthenticated = true;
            sessionStorage.setItem('userId', JSON.stringify(response._id));
        });
        builder.addCase(postUser.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(loginUser.fulfilled, (state, action) => {
            const response = action.payload?.data?.item;
            state.isLoading = false;
            state.row = response;
            state.isAuthenticated = true;
            sessionStorage.setItem('userId', JSON.stringify(response._id));
        });
        builder.addCase(loginUser.rejected, (state) => {
            state.isLoading = false;
            state.isAuthenticated = false;
            state.row = {};
            sessionStorage.removeItem('userId');
        });
        builder.addCase(logoutUser.fulfilled, (state) => {
            state.isLoading = false;
            state.row = {};
            state.isAuthenticated = false;
            sessionStorage.removeItem('userId');
        });
        builder.addCase(logoutUser.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { showLoading, hideLoading, setAuthenticated, setCurrentTab } = userSlice.actions;
export default userSlice.reducer;
