import { CButton, CFormSelect } from '@coreui/react';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, setSearchState } from '../slices/viewSlice';
import { AppDispatch, RootState } from '../store';
import { Constants } from '../utils/constants';
import './searchBar.css';

const SearchBar = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [searchData, setsearchData] = useState({
        gender: '',
        ageFrom: '',
        ageTo: '',
    });
    const loadingState = useSelector((state: RootState) => state.view.loadingState);

    const handleSearch = () => {
        dispatch(setSearchState(searchData));
        dispatch(
            getUsers({
                offset: 0,
                limit: Constants.PAGE_ITEMS_PER_PAGE,
                totalResults: true,
            })
        );
    };
    return (
        <Fragment>
            <div className="search-bar-container">
                <CFormSelect
                    aria-label="Choose Gender"
                    floatingLabel="Gender"
                    value={searchData.gender}
                    onChange={(e) => setsearchData({ ...searchData, gender: e.target.value })}
                >
                    <option key="value" value="any">
                        Any
                    </option>
                    <option key="male" value="male">
                        Male
                    </option>
                    <option key="female" value="female">
                        Female
                    </option>
                </CFormSelect>

                <CFormSelect
                    aria-label="Choose Age From"
                    floatingLabel="Age from"
                    value={searchData.ageFrom}
                    onChange={(e) => {
                        if (e.target.value === 'any') {
                            setsearchData({
                                ...searchData,
                                ageFrom: e.target.value,
                                ageTo: e.target.value,
                            });
                        } else {
                            setsearchData({ ...searchData, ageFrom: e.target.value });
                        }
                    }}
                >
                    <option value="any">Any</option>
                    {(() => {
                        const content = [];
                        for (let i = Constants.MIN_AGE; i <= Constants.MAX_AGE; i++) {
                            content.push(
                                <option key={i} value={i}>
                                    {i}
                                </option>
                            );
                        }
                        return content;
                    })()}
                </CFormSelect>

                <CFormSelect
                    aria-label="Choose Age To"
                    floatingLabel="Age To"
                    value={searchData.ageTo}
                    onChange={(e) => {
                        if (e.target.value === 'any') {
                            setsearchData({
                                ...searchData,
                                ageFrom: e.target.value,
                                ageTo: e.target.value,
                            });
                        } else {
                            setsearchData({ ...searchData, ageTo: e.target.value });
                        }
                    }}
                >
                    <option value="any">Any</option>
                    {(() => {
                        const content = [];
                        for (let i = Constants.MIN_AGE; i <= Constants.MAX_AGE; i++) {
                            content.push(
                                <option key={i} value={i}>
                                    {i}
                                </option>
                            );
                        }
                        return content;
                    })()}
                </CFormSelect>

                <CButton
                    active
                    color="primary"
                    type="submit"
                    variant="ghost"
                    shape="rounded"
                    disabled={loadingState === 'loading'}
                    onClick={handleSearch}
                >
                    Search
                </CButton>
            </div>
        </Fragment>
    );
};

export default SearchBar;
