import { CButton, CForm, CFormInput, CNavLink, CSpinner } from '@coreui/react';
import { FormEvent, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tab, loginUser, setCurrentTab, showLoading } from '../slices/userSlice';
import { AppDispatch, RootState } from '../store';
import './login.css';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const loadingState = useSelector((state: RootState) => state.user.isLoading);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(showLoading());
        try {
            const requestData: any = structuredClone(formData);
            const response = await dispatch(loginUser(requestData)).unwrap();
            if (response) {
                navigate('/profile');
                dispatch(setCurrentTab(Tab.Profile));
            }
        } catch (error: any) {
            setErrorMessage(error?.message || error);
        }
    };

    return (
        <Fragment>
            <div className="login-container">
                {errorMessage && (
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>{errorMessage}</strong>
                        <button
                            type="button"
                            className="btn-close"
                            data-coreui-dismiss="alert"
                            aria-label="Close"
                            onClick={() => setErrorMessage('')}
                        ></button>
                    </div>
                )}
                <CForm onSubmit={handleSubmit} className="login-form">
                    <CFormInput
                        type="email"
                        id="exampleFormControlInput1"
                        label="Email address"
                        placeholder="name@example.com"
                        autoComplete="email"
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    />
                    <CFormInput
                        label="Password"
                        type="password"
                        id="inputPassword"
                        placeholder="********"
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    />

                    {loadingState && (
                        <CButton disabled>
                            <CSpinner size="sm" aria-hidden="true" variant="grow" />
                            Signing in...
                        </CButton>
                    )}

                    {!loadingState && (
                        <CButton
                            active
                            color="primary"
                            type="submit"
                            variant="ghost"
                            shape="rounded"
                        >
                            Sign in
                        </CButton>
                    )}
                </CForm>

                <span>OR</span>

                <span>
                    Don&apos;t have an account?
                    <CNavLink
                        disabled={loadingState}
                        className="login-register-link"
                        href="#"
                        onClick={(event) => {
                            event.preventDefault();
                            navigate('/register');
                            dispatch(setCurrentTab(Tab.Register));
                        }}
                    >
                        Sign up here
                    </CNavLink>
                </span>
            </div>
        </Fragment>
    );
};

export default Login;
