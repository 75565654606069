import { cilPhone, cilSend } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CCard, CCardBody, CCardImage, CCardLink, CCardTitle } from '@coreui/react';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dummyImage from '../assets/dummy-profile.png';
import { Tab, setCurrentTab } from '../slices/userSlice';
import { AppDispatch } from '../store';
import './listItem.css';

const ListItem = ({ item }: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const handleCardClick = () => {
        navigate(`/profile/${item._id}`);
        dispatch(setCurrentTab(Tab.Home));
    };

    return (
        <Fragment>
            <CCard className="listItem-card" onClick={handleCardClick}>
                <CCardImage orientation="top" src={item.avatar?.url || dummyImage} />
                <CCardBody className="listItem-card-overlay">
                    <CCardTitle>
                        {item.name}, {item.age}
                    </CCardTitle>
                    <div className="listItem-contact-section">
                        <span>{item.nativePlace} </span>
                        {item.email && (
                            <CCardLink href={`mailto:${item.email}`}>
                                <CIcon icon={cilSend} size="sm" />
                            </CCardLink>
                        )}
                        {item.phone && (
                            <CCardLink href={`tel:${item.contactPhone}`}>
                                <CIcon icon={cilPhone} size="sm" />
                            </CCardLink>
                        )}
                    </div>
                </CCardBody>

                <CCardBody></CCardBody>
            </CCard>
        </Fragment>
    );
};

export default ListItem;
