import { CCardImage } from '@coreui/react';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import dummyImage from '../assets/dummy-profile.png';
import Horoscope from '../components/horoscope';
import { getUser } from '../slices/viewSlice';
import { AppDispatch, RootState } from '../store';
import './profile.css';

const layoutMetadata = [
    {
        id: 'personal-content',
        label: 'Personal',
        fieldSet: 'style-101',
        fieldSets: [
            [{ name: 'name', label: 'Name' }],
            [{ name: 'nativePlace', label: 'Native' }],
            [
                { name: 'age', label: 'Age' },
                { name: 'height', label: 'Height' },
                { name: 'weight', label: 'Weight' },
            ],
            [{ name: 'education', label: 'Education' }],
            [
                { name: 'martialStatus', label: 'Martial Status' },
                { name: 'motherTongue', label: 'Mother Tongue' },
            ],
            [{ name: 'diet', label: 'Diet' }],
        ],
    },
    {
        id: 'professional-content',
        label: 'Profession',
        fieldSet: 'style-102',
        fields: [
            { name: 'job', label: 'Job' },
            { name: 'jobLocation', label: 'Job Location' },
            { name: 'salary', label: 'Salary' },
        ],
    },
    {
        id: 'Family-content',
        fieldSet: 'style-102',
        label: 'Family',
        fields: [
            { name: 'fatherName', label: 'Father Name' },
            { name: 'fatherJob', label: 'Father Job' },
            { name: 'fatherNativePlace', label: 'Father Native Place' },
            { name: 'motherName', label: 'Mother Name' },
            { name: 'motherJob', label: 'Mother Job' },
            { name: 'motherNativePlace', label: 'Mother Native Place' },
            { name: 'sibilingDetails', label: 'Sibiling Details' },
            { name: 'familyOtherDetails', label: 'Notes' },
        ],
    },
    {
        id: 'horoscope-content',
        fieldSet: 'style-102',
        label: 'Horoscope',
        fields: [
            { name: 'timeOfBirth', label: 'Time Of Birth' },
            { name: 'placeOfBirth', label: 'Place Of Birth' },
            { name: 'gotharam', label: 'Gotharam' },
            { name: 'star', label: 'Star' },
            { name: 'paatham', label: 'Paatham' },
            { name: 'raasi', label: 'Raasi' },
            { name: 'dosham', label: 'Dosham' },
            { name: 'raasiChart', label: 'Raasi Chart' },
            { name: 'navamshaChart', label: 'Navamsha Chart' },
            { name: 'desaiIruppu', label: 'DesaiIruppu' },
            { name: 'horoscopeOtherDetails', label: 'Notes' },
        ],
    },
    {
        id: 'partner-expectation-content',
        label: 'Partner Expectation',
        fieldSet: 'style-102',
        fields: [
            { name: 'partnerAge', label: 'Age' },
            { name: 'partnerDiet', label: 'Diet' },
            { name: 'partnerOtherDetails', label: 'Notes' },
        ],
    },
    {
        id: 'contact-details-content',
        label: 'Contact Details',
        fieldSet: 'style-102',
        fields: [
            { name: 'contactName', label: 'Name' },
            { name: 'contactPhone', label: 'Phone' },
        ],
    },
];

const Profile = () => {
    const userData = useSelector((state: RootState) => state.view.userDetailRow);
    const dispatch = useDispatch<AppDispatch>();
    let { id } = useParams();
    if (!id) {
        id = JSON.parse(sessionStorage.getItem('userId') as string);
    }
    useEffect(() => {
        dispatch(getUser({ id }));
    }, [dispatch]);

    function constructFieldsContainer(field: { name: string; label: string }) {
        if (userData[field.name]?.value) {
            switch (field.name) {
                case 'raasiChart':
                case 'navamshaChart':
                    return (
                        <Horoscope
                            mode="read"
                            value={userData[field.name].value}
                            chartName={field.label}
                        ></Horoscope>
                    );
                default:
                    return (
                        <div className="field-container">
                            <label className="field-label">{field.label}</label>
                            <span className="field-value"> {userData[field.name].value}</span>
                        </div>
                    );
            }
        }
    }

    return (
        <Fragment>
            <div className="profile-container">
                {layoutMetadata.map((section) => {
                    switch (section.fieldSet) {
                        case 'style-101':
                            return (
                                <div>
                                    <h3 className="section-header"> {section.label}</h3>
                                    <section className="fields-container-two-column">
                                        <div className="field-container-two-column">
                                            <CCardImage
                                                className="profile-photo"
                                                orientation="top"
                                                src={userData.avatar?.value?.url || dummyImage}
                                            />
                                        </div>

                                        <div className="field-container-two-column">
                                            {(section.fieldSets || []).map((fieldset, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="field-row-container"
                                                    >
                                                        {fieldset.map((field) => {
                                                            return constructFieldsContainer(field);
                                                        })}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </section>
                                </div>
                            );
                        case 'style-102':
                        default:
                            return (
                                <div>
                                    <h3 className="section-header"> {section.label}</h3>
                                    <section className="fields-container-two-column">
                                        {(section.fields || []).map((field) => {
                                            return constructFieldsContainer(field);
                                        })}
                                    </section>
                                </div>
                            );
                    }
                })}
            </div>
        </Fragment>
    );
};

export default Profile;
