import '@coreui/coreui/dist/css/coreui.min.css';
import axios from 'axios';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Root from './components/root';
import Home from './pages/home';
import Login from './pages/login';
import Profile from './pages/profile';
import Registration from './pages/registration';
import Search from './pages/search';

function App() {
    axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
    console.log(process.env);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Root />}>
                    <Route index element={<Home />} />
                    <Route path="search" element={<Search />} />
                    <Route path="login" element={<Login />} />
                    <Route path="register" element={<Registration />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="profile/:id" element={<Profile />} />
                    <Route path="*" element={<Home />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
