import {
    CButton,
    CCollapse,
    CContainer,
    CHeader,
    CNavItem,
    CNavLink,
    CNavbar,
    CNavbarBrand,
    CNavbarNav,
    CNavbarToggler,
} from '@coreui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tab, logoutUser, setAuthenticated, setCurrentTab } from '../slices/userSlice';
import { AppDispatch, RootState } from '../store';
import './header.css';

const Header = () => {
    const [visible, setVisible] = useState(false);
    const currentTab = useSelector((state: RootState) => state.user.tab);
    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    useEffect(() => {
        const userId = sessionStorage.getItem('userId');
        if (userId && JSON.parse(userId)) {
            dispatch(setAuthenticated());
        }
    }, []);

    const handleLogoutUser = async (event: any) => {
        event.preventDefault();
        try {
            await dispatch(logoutUser(null));
            navigate('/login');
            dispatch(setCurrentTab(Tab.Login));
        } catch (error: any) {
            console.log(error);
        }
    };

    return (
        <CHeader className="header-container">
            <CNavbar expand="sm" colorScheme="dark" className="bg-dark">
                <CContainer fluid>
                    <CNavbarBrand>Sengunthar Matrimony</CNavbarBrand>
                    <CNavbarToggler onClick={() => setVisible(!visible)} />
                    <CCollapse className="navbar-collapse" visible={visible}>
                        <CNavbarNav>
                            <CNavItem>
                                <CNavLink
                                    key="1"
                                    href="#"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        navigate('/');
                                        dispatch(setCurrentTab(Tab.Home));
                                    }}
                                    active={currentTab === Tab.Home}
                                >
                                    Home
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink
                                    key="2"
                                    href="#"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        navigate('/search');
                                        dispatch(setCurrentTab(Tab.Search));
                                    }}
                                    active={currentTab === Tab.Search}
                                >
                                    View
                                </CNavLink>
                            </CNavItem>
                        </CNavbarNav>
                        {!isAuthenticated && (
                            <CNavbarNav>
                                <CNavItem>
                                    <CNavLink
                                        key="3"
                                        href="#"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            navigate('/register');
                                            dispatch(setCurrentTab(Tab.Register));
                                        }}
                                        active={currentTab === Tab.Register}
                                    >
                                        Register
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink
                                        key="4"
                                        href="#"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            navigate('/login');
                                            dispatch(setCurrentTab(Tab.Login));
                                        }}
                                        active={currentTab === Tab.Login}
                                    >
                                        Login
                                    </CNavLink>
                                </CNavItem>
                            </CNavbarNav>
                        )}
                        {isAuthenticated && (
                            <CNavbarNav>
                                <CNavItem>
                                    <CNavLink
                                        key="5"
                                        href="#"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            navigate('/profile');
                                            dispatch(setCurrentTab(Tab.Profile));
                                        }}
                                        active={currentTab === Tab.Profile}
                                    >
                                        My Profile
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CButton
                                        color="primary"
                                        variant="ghost"
                                        onClick={handleLogoutUser}
                                    >
                                        Log Out
                                    </CButton>
                                </CNavItem>
                            </CNavbarNav>
                        )}
                    </CCollapse>
                </CContainer>
            </CNavbar>
        </CHeader>
    );
};

export default Header;
