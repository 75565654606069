import { CFormTextarea } from '@coreui/react';
import { Fragment } from 'react';
import './horoscope.css';

const layoutMetadata = [
    {
        field: 'house1',
        class: 'house1',
    },
    {
        field: 'house2',
        class: 'house2',
    },
    {
        field: 'house3',
        class: 'house3',
    },
    {
        field: 'house4',
        class: 'house4',
    },
    {
        field: 'house5',
        class: 'house5',
    },
    {
        field: 'house6',
        class: 'house6',
    },
    {
        field: 'house7',
        class: 'house7',
    },
    {
        field: 'house8',
        class: 'house8',
    },
    {
        field: 'house9',
        class: 'house9',
    },
    {
        field: 'house10',
        class: 'house10',
    },
    {
        field: 'house11',
        class: 'house11',
    },
    {
        field: 'house12',
        class: 'house12',
    },
];

const Horoscope = (props: any) => {
    return (
        <Fragment>
            {props.mode === 'edit' && (
                <div className="horoscope-chart-container">
                    {layoutMetadata.map((section) => {
                        return (
                            <CFormTextarea
                                key={section.field}
                                rows={4}
                                className={`${section.class} horoscope-chart-cell`}
                                value={props.value?.[section.field] || undefined}
                                onChange={(e) =>
                                    props.onChange({
                                        ...props.value,
                                        [section.field]: e.target.value,
                                    })
                                }
                            />
                        );
                    })}
                    <strong className={`horoscope-title horoscope-chart-cell`}>
                        {props.chartName}
                    </strong>
                </div>
            )}

            {props.mode === 'read' && (
                <div className="horoscope-chart-container">
                    {layoutMetadata.map((section) => {
                        return (
                            <span
                                key={section.field}
                                className={`${section.class} horoscope-chart-cell`}
                            >
                                {props.value?.[section.field]}
                            </span>
                        );
                    })}
                    <strong className={`horoscope-title horoscope-chart-cell`}>
                        {props.chartName}
                    </strong>
                </div>
            )}
        </Fragment>
    );
};

export default Horoscope;
