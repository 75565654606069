import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { RootState } from '../store';
import { Constants } from '../utils/constants';

enum Diet {
    nonvegetarian = 'Non Vegetarian',
    vegetarian = 'Vegetarian',
    eggtarian = 'Eggtarian',
}

enum MartialStatus {
    unmarried = 'Unmarried',
    divorced = 'Divorced',
    widowed = 'Widowed',
}

type SliceState = {
    loadingState: 'loading' | 'error' | 'data';
    rows: Array<any>;
    userDetailRow: any;
    searchState: {
        gender?: 'any' | 'male' | 'female';
        ageFrom?: number | string;
        ageTo?: number | string;
    };
    currentPageSelected: number;
    totalPageCount: number;
};

export const getUser = createAsyncThunk('users/getUser', async (payload: any, thunkAPI) => {
    try {
        return (await axios.get(`/api/v1/users/${payload.id}`)) as AxiosResponse;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
});

export const getUsers = createAsyncThunk('users/getUsers', async (payload: any, thunkAPI) => {
    try {
        const params = new URLSearchParams();
        if (payload.offset) {
            params.set('offset', payload.offset);
        }
        if (payload.limit) {
            params.set('limit', payload.limit);
        }
        if (payload.totalResults) {
            params.set('totalResults', payload.totalResults);
        }
        const searchState = (thunkAPI.getState() as RootState).view.searchState;
        const q: any = {};
        if (searchState.gender && searchState.gender !== 'any') {
            q.gender = searchState.gender;
        }
        if (
            searchState.ageFrom &&
            searchState.ageTo &&
            searchState.ageFrom !== 'any' &&
            searchState.ageTo !== 'any'
        ) {
            q.age = { $gt: searchState.ageFrom, $lt: searchState.ageTo };
        }
        params.set('q', JSON.stringify(q));
        return axios.get(`/api/v1/users`, { params }) as Promise<AxiosResponse>;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState: {
        loadingState: 'loading',
        rows: [],
        searchState: {},
        currentPageSelected: 1,
        totalPageCount: 0,
        userDetailRow: {},
    } as SliceState,
    reducers: {
        showLoading: (state) => {
            return { ...state, loadingState: 'loading' };
        },
        setCurrentPage: (state, { payload }) => {
            return { ...state, currentPageSelected: payload };
        },
        setSearchState: (state, { payload }) => {
            return { ...state, searchState: payload };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUsers.fulfilled, (state, action) => {
            const response = action.payload?.data;
            state.rows = response?.items;
            state.totalPageCount = Math.ceil(
                response?.totalResults / Constants.PAGE_ITEMS_PER_PAGE
            );
            state.loadingState = 'data';
        });
        builder.addCase(getUsers.rejected, (state) => {
            state.loadingState = 'error';
        });
        builder.addCase(getUser.fulfilled, (state, action) => {
            const responseItem = action.payload?.data?.item || {};
            const userDetailRow: any = {};
            Object.keys(responseItem).forEach((field: string) => {
                switch (field) {
                    case 'diet':
                    case 'partnerDiet':
                        userDetailRow[field] = {
                            value: Diet[responseItem[field] as keyof typeof Diet],
                            rawValue: responseItem[field],
                        };
                        break;
                    case 'martialStatus':
                        userDetailRow[field] = {
                            value: MartialStatus[responseItem[field] as keyof typeof MartialStatus],
                            rawValue: responseItem[field],
                        };
                        break;
                    case 'raashiChart':
                    case 'navamshaChart':
                        userDetailRow[field] = {
                            value: JSON.parse(responseItem[field]),
                            rawValue: responseItem[field],
                        };
                        break;
                    default:
                        userDetailRow[field] = {
                            value: responseItem[field],
                            rawValue: responseItem[field],
                        };
                        break;
                }
            });
            state.userDetailRow = userDetailRow;
            state.loadingState = 'data';
        });
        builder.addCase(getUser.rejected, (state) => {
            state.loadingState = 'error';
        });
    },
});

export const { showLoading, setCurrentPage, setSearchState } = userSlice.actions;
export default userSlice.reducer;
