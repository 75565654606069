import {
    CButton,
    CCardImage,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CFormSelect,
    CFormTextarea,
    CSpinner,
} from '@coreui/react';
import { AxiosError } from 'axios';
import { ChangeEvent, FormEvent, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Horoscope from '../components/horoscope';
import { Tab, hideLoading, postUser, setCurrentTab, showLoading } from '../slices/userSlice';
import { AppDispatch, RootState } from '../store';
import './registration.css';

const layoutMetadata = [
    {
        id: 'personal-content',
        label: 'Personal',
        fields: [
            { name: 'avatar', label: 'Photo', type: 'avatar', columnSize: 6 },
            { name: 'name', label: 'Name', type: 'text', columnSize: 6 },
            {
                name: 'gender',
                label: 'Gender',
                type: 'fcl',
                columnSize: 6,
                options: [
                    { label: 'Male', value: 'male' },
                    { label: 'Female', value: 'female' },
                ],
            },
            { name: 'dob', label: 'Date Of Birth', type: 'date', columnSize: 6 },
            { name: 'education', label: 'Education', type: 'text', columnSize: 6 },
            { name: 'nativePlace', label: 'Native Place', type: 'text', columnSize: 6 },
            {
                name: 'martialStatus',
                label: 'Martial Status',
                type: 'fcl',
                columnSize: 6,
                options: [
                    { label: 'Unmarried', value: 'unmarried' },
                    { label: 'Divorced', value: 'divorced' },
                    { label: 'Widowed', value: 'widowed' },
                ],
            },
            {
                name: 'diet',
                label: 'Diet',
                type: 'fcl',
                columnSize: 6,
                options: [
                    { label: 'Vegetarian', value: 'vegetarian' },
                    { label: 'Non Vegetarian', value: 'nonvegetarian' },
                    { label: 'Eggtarian', value: 'eggtarian' },
                ],
            },
            { name: 'motherTongue', label: 'Mother Tongue', type: 'text', columnSize: 6 },
            { name: 'caste', label: 'Caste', type: 'text', columnSize: 6 },
            { name: 'subCaste', label: 'Sub Caste', type: 'text', columnSize: 6 },
            { name: 'religion', label: 'Religion', type: 'text', columnSize: 6 },
            { name: 'height', label: 'Height (cm)', type: 'number', columnSize: 6 },
            { name: 'weight', label: 'Weight (Kg)', type: 'number', columnSize: 6 },
        ],
    },
    {
        id: 'professional-content',
        label: 'Profession',
        fields: [
            { name: 'job', label: 'Job', type: 'text', columnSize: 6 },
            { name: 'jobLocation', label: 'Job Location', type: 'text', columnSize: 6 },
            { name: 'salary', label: 'Monthly Income', type: 'number', columnSize: 6 },
        ],
    },
    {
        id: 'Family-content',
        label: 'Family',
        fields: [
            { name: 'fatherName', label: 'Father Name', type: 'text', columnSize: 6 },
            { name: 'fatherJob', label: 'Father Job', type: 'text', columnSize: 6 },
            { name: 'fatherNativePlace', label: 'Father Native', type: 'text', columnSize: 6 },
            { name: 'motherName', label: 'Mother Name', type: 'text', columnSize: 6 },
            { name: 'motherJob', label: 'Mother Job', type: 'text', columnSize: 6 },
            { name: 'motherNativePlace', label: 'Mother Native', type: 'text', columnSize: 6 },
            {
                name: 'sibilingDetails',
                label: 'Siblings Details',
                type: 'textArea',
                columnSize: 12,
            },
            {
                name: 'familyOtherDetails',
                label: 'Other Details',
                type: 'textArea',
                columnSize: 12,
            },
        ],
    },
    {
        id: 'horoscope-content',
        label: 'Horoscope',
        fields: [
            { name: 'timeOfBirth', label: 'Time Of Birth', type: 'time', columnSize: 6 },
            { name: 'placeOfBirth', label: 'Place Of Birth', type: 'text', columnSize: 6 },
            { name: 'gotharam', label: 'Gotharam', type: 'text', columnSize: 6 },
            { name: 'star', label: 'Star', type: 'text', columnSize: 6 },
            {
                name: 'paatham',
                label: 'Paatham',
                type: 'fcl',
                columnSize: 6,
                options: [
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                ],
            },
            { name: 'raasi', label: 'Raasi', type: 'text', columnSize: 6 },
            { name: 'dosham', label: 'Dhosam', type: 'text', columnSize: 6 },
            { name: 'desaiIruppu', label: 'Desai Iruppu', type: 'text', columnSize: 6 },
            { name: 'raasiChart', label: 'Raasi Chart', type: 'astroChart', columnSize: 6 },
            { name: 'navamshaChart', label: 'Navamsha Chart', type: 'astroChart', columnSize: 6 },
            {
                name: 'horoscopeOtherDetails',
                label: 'Other Details',
                type: 'textArea',
                columnSize: 12,
            },
        ],
    },
    {
        id: 'partner-expectation-content',
        label: 'Partner Expectation',
        fields: [
            { name: 'partnerAge', label: 'Age', type: 'text', columnSize: 6 },
            {
                name: 'partnerDiet',
                label: 'Diet',
                type: 'fcl',
                columnSize: 6,
                options: [
                    { label: 'Vegetarian', value: 'vegetarian' },
                    { label: 'Non Vegetarian', value: 'nonvegetarian' },
                    { label: 'Eggtarian', value: 'eggtarian' },
                ],
            },
            {
                name: 'partnerOtherDetails',
                label: 'Other Details',
                type: 'textArea',
                columnSize: 12,
            },
        ],
    },
    {
        id: 'contact-content',
        label: 'Contact Details',
        fields: [
            { name: 'contactName', label: 'Name', type: 'text', columnSize: 6 },
            { name: 'contactPhone', label: 'Phone', type: 'phone', columnSize: 6 },
        ],
    },
    {
        id: 'login-content',
        label: 'Login Details',
        fields: [
            { name: 'email', label: 'Email', type: 'email', columnSize: 6 },
            { name: 'password', label: 'Password', type: 'password', columnSize: 6 },
            {
                name: 'confirmationPassword',
                label: 'Confirmation Password',
                type: 'password',
                columnSize: 6,
            },
        ],
    },
];

const initialFormState = {
    //personal
    avatar: '',
    name: '',
    gender: 'male',
    dob: new Date().toISOString().split('T')[0],
    education: '',
    nativePlace: '',
    martialStatus: 'unmarried',
    diet: 'nonvegetarian',
    motherTongue: '',
    caste: '',
    subCaste: '',
    religion: '',
    height: 0,
    weight: 0,
    //professional
    job: '',
    jobLocation: '',
    salary: 0,
    //family
    fatherName: '',
    fatherJob: '',
    fatherNativePlace: '',
    motherName: '',
    motherJob: '',
    motherNativePlace: '',
    sibilingDetails: '',
    familyOtherDetails: '',
    //horoscope
    timeOfBirth: '',
    placeOfBirth: '',
    gotharam: '',
    star: '',
    paatham: 1,
    raasi: '',
    dosham: '',
    desaiIruppu: '',
    raasiChart: undefined,
    navamshaChart: undefined,
    horoscopeOtherDetails: '',
    //partner expectation
    partnerAge: '',
    partnerDiet: 'nonvegetarian',
    partnerOtherDetails: '',
    //contact details
    contactName: '',
    contactPhone: '',
    //login details
    email: '',
    password: '',
    confirmationPassword: '',
};

type FormState = keyof typeof initialFormState;

const Registration = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [formData, setFormData] = useState(initialFormState);

    const [errorMessage, setErrorMessage] = useState('');
    const loadingState = useSelector((state: RootState) => state.user.isLoading);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(showLoading());
        try {
            if (formData.password === formData.confirmationPassword) {
                const userData = new FormData();

                Object.keys(formData).forEach((field) => {
                    const fieldValue = (formData as any)[field];
                    if (typeof fieldValue === 'object') {
                        userData.set(field, JSON.stringify(fieldValue));
                    } else if (fieldValue !== undefined) {
                        userData.set(field, fieldValue);
                    }
                });
                userData.delete('confirmationPassword');
                const response: any = await dispatch(postUser(userData)).unwrap();
                navigate(`/profile/${response.data.item._id}`);
                dispatch(setCurrentTab(Tab.Login));
            } else {
                setErrorMessage('Passwords not matching');
                dispatch(hideLoading());
            }
        } catch (error: unknown) {
            setErrorMessage((error as AxiosError)?.message);
        }
    };

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setFormData({ ...formData, avatar: reader.result as string });
            }
        };
        if (event.target?.files?.[0]) {
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    return (
        <Fragment>
            <div className="registration-container">
                <div className="registration-header ">
                    <span>
                        Let&apos;s create your profile! Just fill in the fields below, and we’ll get
                        a new account.
                    </span>
                    {errorMessage && (
                        <div
                            className="alert alert-warning alert-dismissible fade show"
                            role="alert"
                        >
                            <strong>{errorMessage}</strong>
                            <button
                                type="button"
                                className="btn-close"
                                data-coreui-dismiss="alert"
                                aria-label="Close"
                                onClick={() => setErrorMessage('')}
                            ></button>
                        </div>
                    )}
                </div>
                <CForm onSubmit={handleSubmit} className="registration-form">
                    <div className="registration-form-container">
                        {formData.avatar && (
                            <div className="registration-photo-container">
                                <CCardImage
                                    className="registration-photo"
                                    orientation="top"
                                    src={formData.avatar}
                                />
                            </div>
                        )}
                        {layoutMetadata.map((section) => {
                            return (
                                <CContainer key={section.id} id={section.id} className=" row g-3">
                                    <h3 className="section-header"> {section.label}</h3>
                                    {(section.fields || []).map((field) => {
                                        switch (field.type) {
                                            case 'avatar':
                                                return (
                                                    <CCol md={field.columnSize}>
                                                        <CFormInput
                                                            floatingLabel={field.label}
                                                            type="file"
                                                            onChange={(
                                                                e: ChangeEvent<HTMLInputElement>
                                                            ) => handleFileUpload(e)}
                                                        />
                                                    </CCol>
                                                );
                                            case 'date':
                                                return (
                                                    <CCol md={field.columnSize}>
                                                        <div className="form-floating">
                                                            <input
                                                                className="form-control"
                                                                type="Date"
                                                                value={
                                                                    formData[
                                                                        field.name as FormState
                                                                    ] || undefined
                                                                }
                                                                onChange={(e) =>
                                                                    setFormData({
                                                                        ...formData,
                                                                        [field.name]:
                                                                            e.target.value,
                                                                    })
                                                                }
                                                            ></input>
                                                            <label className="form-label">
                                                                {field.label}
                                                            </label>
                                                        </div>
                                                    </CCol>
                                                );
                                            case 'time':
                                                return (
                                                    <CCol md={field.columnSize}>
                                                        <div className="form-floating">
                                                            <input
                                                                className="form-control"
                                                                type="time"
                                                                value={
                                                                    formData[
                                                                        field.name as FormState
                                                                    ] || undefined
                                                                }
                                                                onChange={(e) =>
                                                                    setFormData({
                                                                        ...formData,
                                                                        [field.name]:
                                                                            e.target.value,
                                                                    })
                                                                }
                                                            ></input>
                                                            <label className="form-label">
                                                                {field.label}
                                                            </label>
                                                        </div>
                                                    </CCol>
                                                );
                                            case 'fcl':
                                                return (
                                                    <CCol md={field.columnSize}>
                                                        <CFormSelect
                                                            floatingLabel={field.label}
                                                            value={
                                                                formData[field.name as FormState] ||
                                                                undefined
                                                            }
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    [field.name]: e.target.value,
                                                                })
                                                            }
                                                        >
                                                            {(field.options || []).map((option) => (
                                                                <option
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </CFormSelect>
                                                    </CCol>
                                                );
                                            case 'number':
                                                return (
                                                    <CCol md={field.columnSize}>
                                                        <CFormInput
                                                            type="number"
                                                            floatingLabel={field.label}
                                                            value={
                                                                formData[field.name as FormState] ||
                                                                undefined
                                                            }
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    [field.name]: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </CCol>
                                                );
                                            case 'textArea':
                                                return (
                                                    <CCol md={field.columnSize}>
                                                        <CFormTextarea
                                                            rows={3}
                                                            floatingLabel={field.label}
                                                            value={
                                                                formData[field.name as FormState] ||
                                                                undefined
                                                            }
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    [field.name]: e.target.value,
                                                                })
                                                            }
                                                        ></CFormTextarea>
                                                    </CCol>
                                                );
                                            case 'password':
                                                return (
                                                    <CCol md={field.columnSize}>
                                                        <CFormInput
                                                            type="password"
                                                            floatingLabel={field.label}
                                                            value={
                                                                formData[field.name as FormState] ||
                                                                undefined
                                                            }
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    [field.name]: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </CCol>
                                                );
                                            case 'email':
                                                return (
                                                    <CCol md={field.columnSize}>
                                                        <CFormInput
                                                            type="email"
                                                            floatingLabel={field.label}
                                                            value={
                                                                formData[field.name as FormState] ||
                                                                undefined
                                                            }
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    [field.name]: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </CCol>
                                                );
                                            case 'phone':
                                                return (
                                                    <CCol md={field.columnSize}>
                                                        <CFormInput
                                                            type="tel"
                                                            floatingLabel={field.label}
                                                            value={
                                                                formData[field.name as FormState] ||
                                                                undefined
                                                            }
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    [field.name]: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </CCol>
                                                );
                                            case 'astroChart':
                                                return (
                                                    <Horoscope
                                                        mode="edit"
                                                        chartName={field.label}
                                                        value={
                                                            formData[field.name as FormState] ||
                                                            undefined
                                                        }
                                                        onChange={(value: unknown) =>
                                                            setFormData({
                                                                ...formData,
                                                                [field.name]: value,
                                                            })
                                                        }
                                                    ></Horoscope>
                                                );
                                            default:
                                                return (
                                                    <CCol md={field.columnSize}>
                                                        <CFormInput
                                                            type="text"
                                                            floatingLabel={field.label}
                                                            value={
                                                                formData[field.name as FormState] ||
                                                                undefined
                                                            }
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    [field.name]: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </CCol>
                                                );
                                        }
                                    })}
                                </CContainer>
                            );
                        })}
                    </div>

                    {loadingState && (
                        <CButton className="registration-button" disabled>
                            <CSpinner size="sm" aria-hidden="true" variant="grow" />
                            Registering...
                        </CButton>
                    )}

                    {!loadingState && (
                        <CButton
                            className="registration-button"
                            active
                            color="primary"
                            type="submit"
                            variant="ghost"
                            shape="rounded"
                        >
                            Register
                        </CButton>
                    )}
                </CForm>
            </div>
        </Fragment>
    );
};

export default Registration;
