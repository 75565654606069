import { CCardImage } from '@coreui/react';
import { Fragment } from 'react';
import homePageCover from '../assets/home-cover.webp';
import './home.css';

const Home = () => {
    return (
        <Fragment>
            <div className="home-container">
                <div className="home-photo-container">
                    <CCardImage className="home-photo" orientation="top" src={homePageCover} />
                    <h5 className="home-photo-overlay">
                        <span>Welcome To Sengunthar Matrimony.</span>{' '}
                        <span>
                            Today, we are the most successful and most trusted matrimony website.
                        </span>{' '}
                        Millions of happy marriages happened and continue to happen through
                        Sengunthar Matrimony
                    </h5>
                </div>
                <div className="home-contact-container">
                    <h3 className="section-header"> Contact Us</h3>
                    <strong>PR Rajendran</strong>
                    <strong>Phone: 918608972222</strong>
                </div>
            </div>
        </Fragment>
    );
};

export default Home;
